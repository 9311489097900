/* ManageAttributes.css */
.table-scroll {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .table-scroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .table-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  
  .centralized-input .MuiInputBase-root {
    margin-top: 8px;
    margin-bottom: 8px;
  }