.seller-dashboard {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.seller-dashboard h1, 
.seller-dashboard h2, 
.seller-dashboard h3, 
.seller-dashboard h4, 
.seller-dashboard h5, 
.seller-dashboard h6 {
    font-family: 'Helvetica', sans-serif;
    color: #333;
}

.seller-dashboard button {
    font-family: 'Arial', sans-serif;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.seller-dashboard button:hover {
    background-color: #0056b3;
}

.seller-dashboard .table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: white;
}

.seller-dashboard .table th, 
.seller-dashboard .table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.seller-dashboard .table th {
    background-color: #007BFF;
    color: white;
}

.seller-dashboard .table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.seller-dashboard .table tr:hover {
    background-color: #f1f1f1;
}

.seller-dashboard .modal {
    background-color: white;
    color: #333;
    padding: 20px;
    border-radius: 3px;
    max-width: 800px;
    margin: 50px auto;
}

.seller-dashboard .modal-header, 
.seller-dashboard .modal-footer {
    border-bottom: 1px solid #ddd;
}

.seller-dashboard .modal-header {
    padding-bottom: 10px;
}

.seller-dashboard .modal-footer {
    padding-top: 10px;
    text-align: right;
}

.seller-dashboard .modal-body {
    padding: 20px 0;
}

.seller-dashboard .tab-list {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

.seller-dashboard .tab {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.seller-dashboard .tab:hover {
    background-color: #f1f1f1;
}

.seller-dashboard .tab-active {
    background-color: #007BFF;
    color: white;
}

.seller-dashboard .flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.seller-dashboard .flex-item {
    flex: 1 1 200px;
    background-color: white;
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.seller-dashboard input[type="date"], 
.seller-dashboard input[type="text"] {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 3px;
    color: #333;
    padding: 10px;
    margin: 5px;
    width: calc(100% - 30px);
}

.seller-dashboard select {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 3px;
    color: #333;
    padding: 10px;
    margin: 5px;
    width: calc(100% - 30px);
}

.seller-dashboard .table thead th {
    background-color: #007BFF;
    color: white;
    border-bottom: 2px solid #0056b3;
}

.seller-dashboard .table tbody tr {
    transition: background-color 0.3s;
}

.seller-dashboard .table tbody tr:hover {
    background-color: #f1f1f1;
}

.seller-dashboard .flex-item .text {
    font-size: 1.2em;
}

.seller-dashboard .modal-content {
    background-color: white;
    border-radius: 3px;
    padding: 20px;
}

.seller-dashboard .modal-content .modal-header, 
.seller-dashboard .modal-content .modal-footer {
    border-bottom: 1px solid #ddd;
}

.seller-dashboard .modal-content .modal-header {
    padding-bottom: 10px;
}

.seller-dashboard .modal-content .modal-footer {
    padding-top: 10px;
    text-align: right;
}

.seller-dashboard .modal-content .modal-body {
    padding: 20px 0;
}

.seller-dashboard .modal-content .close {
    color: #007BFF;
}

.seller-dashboard .modal-content .btn-close {
    color: #007BFF;
    font-size: 1.2em;
    cursor: pointer;
    transition: color 0.3s;
}

.seller-dashboard .modal-content .btn-close:hover {
    color: #0056b3;
}

.seller-dashboard .table a {
    text-decoration: underline;
    color: #007BFF;
    cursor: pointer;
}

.seller-dashboard .table a:hover {
    color: #0056b3;
}

/* In SellerDashBoard.css */

.approve-button {
    background-color: green !important;
    color: white !important;
    border: none !important;
  }
  
  .reject-button {
    background-color: red !important;
    color: white !important;
    border: none !important;
  }
  
  .approve-button:hover {
    background-color: darkgreen !important;
    color: white !important;
  }
  
  .reject-button:hover {
    background-color: darkred !important;
    color: white !important;
  }
  