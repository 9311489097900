.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whatsapp_float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #06ce4f;
  color: #ffffff;
  border-radius: 160px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile styles */
@media (max-width: 768px) {
  .whatsapp_float {
    width: 60px;
    height: 60px;
    font-size: 20px;
    bottom: 65px;
    right: 10px;
  }

  .whatsapp_float .whatsapp-icon {
    font-size: 50px;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .whatsapp_float {
    width: 70px;
    height: 70px;
    font-size: 25px;
  }

  .whatsapp_float .whatsapp-icon {
    font-size: 70px;
  }
}

.tooltip {
  position: absolute;
  text-align: center;
  width: 120px;
  height: 28px;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}

.fa-headset {
  font-size: 2em;
  color: rgba(255, 0, 0, 0.425); /* Customize as needed */
}
