/* FirstOrderList.css */

.first-order-list-container {
    margin-top: 20px;
  }
  
  .first-order-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .first-order-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .first-order-table th,
  .first-order-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .first-order-table th {
    background: #007bff;
    color: white;
    font-weight: normal;
  }
  
  .first-order-table td button {
    text-decoration: underline;
    border: none;
    background: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
  }
  
  .first-order-table td button:hover {
    color: #0056b3;
  }
  
  .approve-button {
    background-color: green !important;
    color: white !important;
    border: none !important;
  }
  
  .reject-button {
    background-color: red !important;
    color: white !important;
    border: none !important;
  }
  
  .approve-button:hover {
    background-color: darkgreen !important;
    color: white !important;
  }
  
  .reject-button:hover {
    background-color: darkred !important;
    color: white !important;
  }